const resource = {
    en: {
        register: 'Register',
        title: 'Title',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        password: 'Password',
        password_confirmation: 'Password Confirmation',
        register_button: 'Register',
        register_with_teem_account: 'Register with TEEM-Account?',
        logged_in: {
            heading: 'You are already logged in',
            home_button: 'Back to Home'
        },
        privacy_policy: 'I acknowledge the privacy policy and accept all terms.',
        notifications: {
            service_unavailable: 'Service not available',
            privacy_policy: 'You need to accept the privacy policy to register.',
            valid_email: 'The given email address is not valid.',
            password_confirmation: 'The confirmation of the password does not match the password.',
            password_too_short: 'The password must have at least 6 characters.',
            taken_email: 'The given email address is already taken.'
        }
    },
    de: {
        register: 'Registrierung',
        title: 'Titel',
        first_name: 'Vorname',
        last_name: 'Nachname',
        email_address: 'Email Adresse',
        password: 'Passwort',
        password_confirmation: 'Passwort wiederholen',
        register_button: 'Registrieren',
        register_with_teem_account: 'Registerung mit TEEM-Account?',
        logged_in: {
            heading: 'Sie sind schon eingeloggt',
            home_button: 'Zurück zur Homepage'
        },
        privacy_policy: 'Ich habe die Datenschutzerklärung zur Kenntnis genommen und akzeptiert.',
        notifications: {
            service_unavailable: 'Der Dienst steht gerade nicht zur Verfügung',
            privacy_policy: 'Sie müssen die Datenschutzerklärung akzeptieren um sich zu registrieren.',
            valid_email: 'Die angegebene E-Mail-Adresse ist nicht gültig.',
            password_confirmation: 'Die Bestätigung des Passworts stimmt nicht mit dem Passwort überein.',
            password_too_short: 'Das Passwort muss zumindest 6 Zeichen enthalten.',
            taken_email: 'Ein Profil exisitiert bereits mit dieser E-Mail-Adresse.'
        }
    }
}

export default resource;