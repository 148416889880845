<template>
    <div>
        <div class="authentication-card" v-if="user">
            <h1 class="authentication-heading" v-html="resource[lang].logged_in.heading" />
            <Button stretch rounded submit
                class="authentication-button" 
                color="primary" 
                :label="resource[lang].logged_in.home_button" 
                @click="$router.push('/')"
            />
        </div>

        <div v-else>
            <form class="authentication-card" @submit.prevent="register">
                <h1 class="authentication-heading" v-html="resource[lang].register" />
                <AuthenticationInputText
                    class="authentication-input" 
                    v-model="title" 
                    :placeholder="resource[lang].title"
                />
                <AuthenticationInputText required
                    class="authentication-input" 
                    v-model="first_name" 
                    :placeholder="resource[lang].first_name"
                />
                <AuthenticationInputText required
                    class="authentication-input" 
                    v-model="last_name" 
                    :placeholder="resource[lang].last_name"
                />
                <AuthenticationInputText required
                    class="authentication-input" 
                    v-model="email" 
                    :placeholder="resource[lang].email_address"
                />
                <AuthenticationInputText required password
                    class="authentication-input" 
                    v-model="password" 
                    :placeholder="resource[lang].password" 
                />
                <AuthenticationInputText required password
                    class="authentication-input" 
                    v-model="password_confirmation" 
                    :placeholder="resource[lang].password_confirmation"
                />

                <q-toggle
                    class="toggle"
                    v-model="accept_privacy_policy"
                    color="primary"
                    keep-color
                    :label="resource[lang].privacy_policy"
                />

                <Button stretch rounded submit
                    class="authentication-button" 
                    color="accent" 
                    :label="resource[lang].register_button"
                />
            </form>
            
            <!-- <AuthenticationLink link="/register-with-teem" :label="resource[lang].register_with_teem_account" /> -->
        </div>
    </div>
</template>

<script>
import { useQuasar } from 'quasar'
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/register-resource'

import AuthenticationInputText from '@/components/basic/AuthenticationInputText'
// import AuthenticationLink from '@/components/basic/AuthenticationLink'
import Button from '@/components/basic/Button'

import axios from 'axios'

export default {
    components: {
        AuthenticationInputText,
        // AuthenticationLink,
        Button
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

       return {
           lang: lang,

           title: '',
           first_name: '',
           last_name: '',
           email: '',
           password: '',
           password_confirmation: '',

           accept_privacy_policy: false,

           resource
       } 
    },
    computed: {
        ...mapGetters(['user']),
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Request
        registerRequest() {
            const request_body = {
                title: this.title,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }
            return new Promise((resolve, reject) => {
            axios.post('register', request_body)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    this.handleError(error);
                    reject();
                    this.$store.dispatch('loading', false);
                });
            });
        },

        // Action
        async register() {
            if (this.accept_privacy_policy) {
                this.$store.dispatch('loading', true);
                const response = await this.registerRequest();
                this.$store.dispatch('loading', false);

                if (response.status === 'success' || response.status === 200) {
                    this.$router.push('/registration-confirmation');
                }
            } else {
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].notifications.privacy_policy, 
                    color: 'negative'
                });
            }
        },
        handleError(response) {
            if (response.response.status == 422) {
                for (let issue in response.response.data) {
                    for (let description of response.response.data[issue]) {
                        console.log(description)
                        if (description.includes('The email has already been taken.')) {
                            this.quasar.notify({
                                position: 'bottom-left', 
                                message: this.resource[this.lang].notifications.taken_email, 
                                color: 'negative'
                            });
                        } else if (description.includes('The email must be a valid email address.')) {
                            this.quasar.notify({
                                position: 'bottom-left', 
                                message: this.resource[this.lang].notifications.valid_email, 
                                color: 'negative'
                            });
                        }
                        if (description.includes('password')) {
                            if (description.includes('password confirmation')) {
                                this.quasar.notify({
                                    position: 'bottom-left', 
                                    message: this.resource[this.lang].notifications.password_confirmation, 
                                    color: 'negative'
                                });
                            }
                            if (description.includes('6 characters')) {
                                this.quasar.notify({
                                    position: 'bottom-left', 
                                    message: this.resource[this.lang].notifications.password_too_short, 
                                    color: 'negative'
                                });
                            }
                        }
                    }
                }
            } else {
                this.quasar.notify({
                    position: 'bottom-left', 
                    message: this.resource[this.lang].notifications.service_unavailable, 
                    color: 'negative'
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.toggle {
    margin-top: 1.5rem;
}

</style>