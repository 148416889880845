<template>
    <input 
        :type="password ? 'password' : 'text'" 
        :modelValue="modelValue" 
        :placeholder="placeholder"
        :required="required"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>

<script>
export default {
    props: {
        modelValue: String,
        placeholder: String,
        password: Boolean,
        required: Boolean,
    },
    emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>

input {
    display: block;
    width: 100%;

    background-color: var(--whit);
    color: var(--dark_grey);
    border: solid 2px var(--dark_grey);
    border-radius: 5px;

    font-family: var(--standard-font);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;

    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-block-start: 0.6rem;
    padding-block-end: 0.6rem;
}

input::placeholder {
    color: var(--dark_grey);
    font-family: var(--standard-font);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
}

input:focus-visible,
input:hover,
input:focus-visible::placeholder,
input:hover::placeholder {
    outline: none;
    color: var(--blak);
    // border-color: var(--blak);
}

</style>